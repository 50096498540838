import {
  Row,
  Col,
  Card,
  Alert,
  Image,
  Button,
  Container,
} from "react-bootstrap";
import React from "react";
import uploadedImageUrl from "../../assets/images/SuccessCheck.png";

const StatusPage = () => {
  return (
    <Container className="text-center my-5">
      <Card className="borderless text-center" style={{ borderRadius: 8 }}>
        <Card.Body>
          <Row>
            <Col>
              <Alert variant="success">
                <Alert.Heading>Upload Successful!</Alert.Heading>
                <p>Your image has been successfully uploaded.</p>
              </Alert>
              <Image
                className="mt-4"
                src={uploadedImageUrl}
                alt="Uploaded"
                style={{ height: 150, width: 150 }}
                fluid
              />
              <p className="lead mt-4">
                Thank you for uploading your image. It has been saved
                successfully.
              </p>
              <Button variant="primary" href="/" className="mt-3">
                Go to Home
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StatusPage;
