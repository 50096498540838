import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import React from "react";

// import Apb from "./views/pages/apb";
// import Identification from "./views/pages";
import ApbCertificate from "./views/pages/ApbCertificate";

import StatusPage from "./views/statuspage";

// import Riskcovry from "./views/pages/riskcovry";

// import LandingPage from "./views/pages/dimsmobile/LandingPage";
// import ProtectForm from "./views/pages/dimsmobile/ProtectForm";

// import DmiFinance from "./views/pages/medicalmobile";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" element={<Identification />} /> */}

        {/* ****** Apb Page ****** */}
        {/* <Route exact path="/" element={<Apb />} /> */}
        <Route exact path="/apb-certificate" element={<ApbCertificate />} />

        {/* ****** Upload Successful Page ****** */}
        <Route exact path="/statusPage" element={<StatusPage />} />

        {/* ****** Riskcovry Page ****** */}
        {/* <Route exact path="/" element={<Riskcovry />} /> */}

        {/* ****** DMI Mobile Page ****** */}
        {/* <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/protect-form" element={<ProtectForm />} /> */}

        {/* ****** DMI Finance Page ****** */}
        {/* <Route exact path="/" element={<DmiFinance />} /> */}

        {/* <Route path="/payment-success" element={<PaymentSuccessPage />} /> */}

        {/* When we enter wrong route */}
        <Route path="*" element={<Navigate to="/apb-certificate" />} />
      </Routes>
    </Router>
  );
}

export default App;
